import React, { useCallback } from "react";
import { observer } from "mobx-react";
import BaseModal from "@app/components/modal";
import { Form } from "antd";
import notify from "@app/components/notify";
import Mention from "@app/components/mention";
import assignedStore from "@app/state/store/projects/assigned";

import commentStore from "@app/state/store/report-document/comment";

const ENTER_KEY = "Enter";

const CommentModal = observer(() => {
    const { modalState } = commentStore;
    const {
        isOpen,
        isSectionComment,
        from,
        to,
        selectedText,
        sectionId,
        onModalClose,
        modalPosition = {},
    } = modalState;
    const users = assignedStore.getAllUsers();

    const { top, left } = modalPosition;
    const [form] = Form.useForm();

    let style = {};

    if (top && left) {
        style = { top: `${top}px`, left: `${left}px`, position: "fixed" };
    }

    const closeModal = () => {
        commentStore.closeAddCommentModal();
        form.resetFields();
        onModalClose && onModalClose();
    };

    const handleSaveComment = useCallback(async ({ text }) => {
        const commentData = {
            text,
            section: sectionId,
            selectedText,
            isSectionComment,
            isParsed: isSectionComment,
            from,
            to,
        };

        closeModal();

        await commentStore.saveComment(commentData);
    });

    const onError = () => {
        notify.error("Please enter a comment");
    };

    const onSubmit = async (data) => {
        await handleSaveComment({ text: data.comment });
    };

    const onKeyDown = async (event) => {
        if (event.key === ENTER_KEY && event.shiftKey) {
            event.preventDefault();
            form.submit();
        }
    };

    if (!isOpen) {
        return null;
    }

    return (
        <BaseModal
            title="New Comment"
            visible={true}
            onOk={form.submit.bind(form)}
            okText="Add"
            onCancel={closeModal}
            bodyStyle={{ overflow: "visible" }}
            style={style}
            mask={false}
            wrapClassName="selectable-background-modal"
        >
            <Form
                form={form}
                layout="vertical"
                name="commentForm"
                onFinish={onSubmit}
                onFinishFailed={onError}
            >
                <Form.Item
                    label="Comment"
                    name="comment"
                    rules={[
                        {
                            required: true,
                            message: "Please add a comment",
                            whitespace: true,
                        },
                    ]}
                >
                    <Mention users={users} onKeyDown={onKeyDown} focus={true} />
                </Form.Item>
            </Form>
        </BaseModal>
    );
});

export default CommentModal;
