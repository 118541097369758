import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Page } from "@app/components/page";
import EmptyList from "@app/components/list/empty";

import CategoryCard from "@app/components/document/category/card";
import CategoryFilter from "@app/components/document/category/filter";

import Upload from "@app/components/form/upload/button";
import Progress from "@app/components/form/upload/progress";
import DropArea from "@app/components/form/upload/drop/area";

import reportStore from "@app/state/store/report";
import categories from "@app/state/store/report/document/categories";
import documentStore from "@app/state/store/report/document/details";

import "./style/categories.scoped.scss";
import DownloadButton from "@app/components/form/download/download-button";
import documents from "@app/state/store/report/document/list";
import { MultiSelect } from "@app/components/multi-select";

const List = observer(() => {
    const config = reportStore.config || {};

    useEffect(() => {
        if (config.sourceDocuments?.enabled === true) {
            categories.load();
        }
    }, [config.sourceDocuments]);

    const baseUrl = `/r/${categories.project}/document/categories`;

    const onUpload = async (data) => {
        await documentStore.upload(data);
    };

    const readonly = reportStore.readOnly;

    const allFilesCount =
        categories.list?.find((c) => c.name === "All Files")?.documents?.count || 0;

    return (
        <Page className="categories-list">
            <Page.Header>
                <Page.Header.Left>
                    <Page.Title>Source Document Categories</Page.Title>
                </Page.Header.Left>
                <Page.Header.Right>
                    <MultiSelect totalList={documents.list.map((d) => d._id)}>
                        <DownloadButton
                            allCategories={true}
                            filterParams={{}}
                            disabled={documents.loading || allFilesCount === 0}
                        />
                    </MultiSelect>
                    {!readonly && (
                        <Upload
                            onChange={onUpload}
                            state={documentStore.uploadState}
                            multiple
                            primary
                        />
                    )}
                </Page.Header.Right>
            </Page.Header>

            <Page.Layout>
                <Page.Body filter={categories.filter}>
                    <DropArea multiple state={documentStore.uploadState} onChange={onUpload}>
                        <Progress state={documentStore.uploadState} />

                        <EmptyList
                            title="No Categories Found"
                            show={categories.list.length === 0 && !categories.loading}
                        ></EmptyList>

                        <div className="categories">
                            {categories.list.map((category) => {
                                return (
                                    <Link
                                        to={`${baseUrl}/${category._id}`}
                                        onClick={() => focus(category._id)}
                                        key={category._id}
                                    >
                                        <CategoryCard category={category} />
                                    </Link>
                                );
                            })}
                        </div>
                    </DropArea>
                </Page.Body>

                <CategoryFilter filter={categories.filter} config={reportStore.config} />
            </Page.Layout>
        </Page>
    );
});

export default List;
