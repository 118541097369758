import React, { useEffect } from "react";
import { observer, useLocalStore } from "mobx-react";
import classNames from "classnames";
import filter from "@app/lib/format";
import { Input } from "antd";

import "../style/content.scoped.scss";

const TEXT_LIMIT = 160;

export const FoldedContent = observer(({ content, onChange, onFocus, disableEdit, ...rest }) => {
    const state = useLocalStore(() => ({
        shortened: false,
        fold: false,
        short: null,
    }));

    useEffect(() => {
        state.shortText = filter.shorten(content, TEXT_LIMIT);
        state.shortened = state.shortText !== content;
        state.fold = state.shortened;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggle = (e) => {
        e.stopPropagation();
        state.fold = !state.fold;
    };

    return (
        <div
            className={classNames("content", {
                shortened: state.shortened,
                folded: state.fold,
            })}
            {...rest}
            onClick={onFocus}
        >
            {disableEdit ? (
                <>
                    <span className="short" onClick={onFocus}>
                        {state.shortText}
                    </span>
                    <span className="full" onClick={onFocus}>
                        {content}
                    </span>
                    <br />
                    <div className="more" onClick={toggle}>
                        {state.fold ? " read more" : "read less"}
                    </div>
                </>
            ) : (
                <Input.TextArea
                    style={{ resize: "none" }}
                    autoSize={{ minRows: 6, maxRows: 12 }}
                    value={content}
                    onChange={(e) => {
                        onChange && onChange(e.currentTarget.value);
                    }}
                />
            )}
        </div>
    );
});

export default FoldedContent;
