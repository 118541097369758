import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import { Button, Tag, Menu, Dropdown } from "antd";
import { EllipsisOutlined, FileSearchOutlined, FileExcelOutlined } from "@ant-design/icons";

import { MultiSelect, MultiAction } from "@app/components/multi-select";

import { Page } from "@app/components/page";
import Filter from "@app/components/filter";
import EmptyList from "@app/components/list/empty";
import DocumentCard from "@app/components/document/card";
import DocumentFilter from "@app/components/document/filter";

import Upload from "@app/components/form/upload/button";
import Progress from "@app/components/form/upload/progress";
import DropArea from "@app/components/form/upload/drop/area";
import { useSplitPage } from "@app/components/page/split/context";
import useHistoryFilters from "@app/components/hooks/useHistoryFilters";
import confirm from "@app/components/confirm";

import reportStore from "@app/state/store/report";
import documents from "@app/state/store/report/document/list";
import documentStore from "@app/state/store/report/document/details";
import categories from "@app/state/store/report/document/categories";
import session from "@app/state/store/session";

import notify from "@app/components/notify/index";
import { CategoryStatus, CustomCategories } from "@app/constants";
import qs from "qs";

import "./style/list.scoped.scss";
import DownloadButton from "@app/components/form/download/download-button";
import { useContext as useMultiSelectContext } from "@app/components/multi-select/context";

const List = observer(() => {
    const history = useHistory();
    const { search } = useLocation();

    const split = useSplitPage();

    const config = reportStore.config || {};
    const { category: categoryId, document: documentId, project } = useParams();
    const baseUrl = `/r/${project}/document/categories/${categoryId}`;
    const [focused, setFocused] = useState(documentId);
    const [allCategories, setAllCategories] = useState([]);

    const category = categories.getDetails(categoryId);

    const readonly = reportStore.readOnly;

    useEffect(() => {
        if (config.sourceDocuments?.enabled === true) {
            documents.load(categoryId);
        }
        if (categories) {
            (async () => {
                setAllCategories(await categories.configuredCategories());
            })();
        }
    }, [categoryId, category, config.sourceDocuments]);

    useHistoryFilters(documents.filter);

    if (!category) {
        return;
    }
    const specialCategory =
        category._id === CustomCategories.ALL || category._id === CustomCategories.UNCATEGORIZED;
    const canApprove =
        session.can("sourceDocuments.review") && !specialCategory && !reportStore.readOnly;

    const close = () => {
        history.push(`/r/${documents.project}/document/categories`);
    };

    const toggleStatus = async (status) => {
        const success = await categories.update(categoryId, { status });
        if (success) {
            notify.info("Category updated successfully");
        }
    };

    const onUpload = async (data) => {
        const category = specialCategory ? undefined : documents.category;
        await documentStore.upload(data, category);
    };

    const onDelete = async (id) => {
        let proceed = await confirm("Are you sure you want to delete this document?");

        if (proceed) {
            await documents.delete(id);
            history.push(`/r/${documents.project}/document/categories/${categoryId}`);
        }
    };

    const open = (id) => {
        history.push(`${baseUrl}/${id}/open`);
    };

    const menu = (
        <Menu className="menu">
            {canApprove && category.status === CategoryStatus.APPROVED && (
                <Menu.Item
                    onClick={() => toggleStatus(CategoryStatus.PENDING)}
                    icon={<FileSearchOutlined />}
                >
                    Mark as pending
                </Menu.Item>
            )}
        </Menu>
    );

    const MultiSelectWrapper = observer(() => {
        const { context } = useMultiSelectContext();
        const { reset } = context;

        return (
            <>
                <Filter.Header
                    filter={documents.filter}
                    toggle
                    sorting
                    showMultiAction={true}
                    multiAction={!readonly && <MultiAction />}
                    afterSearch={() => {
                        reset();
                    }}
                />
            </>
        );
    });

    let exportUrl = `/api/project/${documents.project}/documents/export?`;

    const params = documents.filter.value();
    params.category = categoryId;
    params.format = "excel";

    const queryString = qs.stringify(params);
    exportUrl += queryString;

    return (
        <Page className="documents">
            <MultiSelect totalList={documents.list.map((d) => d._id)}>
                <Page.Header closable onClose={close}>
                    <Page.Header.Left>
                        <Page.Title className="page-title">
                            <Status status={category?.status} className="status" /> {category?.name}
                        </Page.Title>
                    </Page.Header.Left>

                    {!split.isSplit && (
                        <Page.Header.Right>
                            {canApprove && category?.status === CategoryStatus.PENDING && (
                                <Button
                                    type="success"
                                    onClick={() => toggleStatus(CategoryStatus.APPROVED)}
                                >
                                    Approve
                                </Button>
                            )}
                            <DownloadButton
                                categoryId={categoryId}
                                disabled={documents.loading || documents.list.length === 0}
                            />
                            <Upload
                                onChange={onUpload}
                                state={documentStore.uploadState}
                                multiple
                                primary
                            />

                            <Button
                                type="default"
                                icon={<FileExcelOutlined />}
                                href={exportUrl}
                                target="_blank"
                            >
                                Export
                            </Button>

                            {canApprove && category?.status === CategoryStatus.APPROVED && (
                                <Dropdown overlay={menu} trigger="click" placement="bottomRight">
                                    <Button icon={<EllipsisOutlined />} />
                                </Dropdown>
                            )}
                        </Page.Header.Right>
                    )}
                </Page.Header>

                <MultiSelectWrapper />
                <Page.Layout>
                    <Page.Body filter={documents.filter}>
                        <DropArea multiple state={documentStore.uploadState} onChange={onUpload}>
                            <Progress state={documentStore.uploadState} />

                            <EmptyList
                                title="No Documents Found"
                                show={documents.list.length === 0 && !documents.loading}
                            ></EmptyList>

                            {documents.list.map((document) => {
                                return (
                                    <Link
                                        to={{
                                            pathname: `${baseUrl}/${document._id}`,
                                            search,
                                        }}
                                        onClick={() => setFocused(document._id)}
                                        key={document._id}
                                    >
                                        <DocumentCard
                                            document={document}
                                            focus={focused === document._id}
                                            onOpen={() => open(document._id)}
                                            onDelete={() => onDelete(document._id)}
                                            assignedTags={document.documentTags}
                                            checkedBox={!readonly}
                                            readonly={readonly}
                                        />
                                    </Link>
                                );
                            })}
                        </DropArea>
                    </Page.Body>

                    {!split.isSplit && (
                        <DocumentFilter
                            filter={documents.filter}
                            config={reportStore.config}
                            categoryScreen={categoryId}
                            allCategories={allCategories}
                        />
                    )}
                </Page.Layout>

                <Page.Footer>
                    <Filter.Pager filter={documents.filter} />
                </Page.Footer>
            </MultiSelect>
        </Page>
    );
});

const Status = observer(({ status }) => {
    if (status === CategoryStatus.PENDING) {
        return <Tag className="tag">Pending</Tag>;
    } else if (status === CategoryStatus.APPROVED) {
        return (
            <Tag color="green" className="tag">
                Approved
            </Tag>
        );
    }
    return null;
});

export default List;
