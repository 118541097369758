import { computed, action } from "mobx";
import { v4 as uuid } from "uuid";
import { events } from "@app/lib/store";
import BaseStore from "@app/state/store/base";
import http from "@app/lib/http";
import _ from "lodash";

import Article from "@app/state/model/project/article";
import Highlight from "@app/state/model/highlight";
import Comment from "@app/state/model/comment";

import report from "../report";
import { ws } from "@app/lib/socket";
import notify from "@app/components/notify";
import { AutoLoadingState } from "@app/state/decorators/auto-loading-state";

const STGR_LOAD = uuid();

export class ArticleStore extends BaseStore {
    /**
     * Observable store data
     */
    observable() {
        return {
            highlights: [],
            comments: [],
            article: null,
            loading: false,
            saving: false,
            loaded: false,
            sidebarTab: undefined,
        };
    }

    constructor() {
        super();
        ws.on("article.changed", (id) => {
            if (this.article?._id === id) {
                this.load(id, true);
            }
        });

        events.on("project.unload", () => {
            this.reset();
        });

        events.on("search.update", () => {
            this.reset();
        });
    }

    @computed get busy() {
        return this.loading || this.saving;
    }

    /**
     * Return the project id if the currently loaded project
     */
    @computed get project() {
        return report.id;
    }

    /**
     * Load an article details
     */
    @action
    async load(id, force = false) {
        // do not load the article data twice
        if (this.article?._id === id && force === false) {
            return;
        }

        this.loaded = false;
        let { data } = await http.get(`/project/${this.project}/article/${id}`).stagger(STGR_LOAD);
        this.article = null;

        if (data._id) {
            this.article = new Article(data);

            await this.loadHighlights();
            await this.loadComments();
        }

        this.loaded = true;
    }

    /**
     * Update user details
     */
    @action
    async update(params) {
        if (!this.article) {
            return;
        }

        try {
            this.saving = true;
            this.article.set(params);

            let { data } = await http.post(
                `/project/${this.project}/article/${this.article._id}`,
                params,
            );

            this.article.set(data);
            events.emit("article.update", this.article);
        } finally {
            this.saving = false;
        }
    }

    // updateMany
    @action
    async updateMany(articleIds, type, values) {
        if (articleIds?.length === 0 || _.isEmpty(values)) {
            return;
        }

        try {
            this.saving = true;
            await http.post(`/project/${this.project}/articles`, {
                articleIds,
                type,
                values,
            });

            events.emit("article.updateMany");
            this.reset();
        } finally {
            this.saving = false;
        }
    }

    /**
     * Remove a file
     */
    @action
    async removeFile() {
        if (!this.article) {
            return;
        }

        try {
            this.saving = true;

            // Update the article by removing the file field.
            const { data } = await http.delete(
                `/project/${this.project}/article/${this.article._id}/file`,
            );

            this.article.file = data.file;
            events.emit("article.update", this.article);
        } catch (ex) {
            notify.error(
                ex.response?.data?.error || "An error occurred while trying to remove file",
            );
        } finally {
            this.saving = false;
        }
    }

    /**
     * Explicitly assign a DOI to an
     */
    @action
    async assignDoi(doi) {
        if (!this.article) {
            return;
        }

        try {
            this.saving = true;

            let { data } = await http.post(
                `/project/${this.project}/article/${this.article._id}/doi`,
                {
                    doi,
                },
            );

            this.article.set(data);
            events.emit("article.update", this.article);
        } finally {
            this.saving = false;
        }
    }

    /**
     * Mark the article as passed an L1/L2 stage
     */
    @action
    async pass({ stage, type }) {
        this.saving = true;

        let { data } = await http.post(
            `/project/${this.project}/article/${this.article._id}/${stage}/pass`,
            {
                type,
            },
        );

        this.article.set(data);
        events.emit("article.update", this.article);
        this.saving = false;
    }

    /**
     * Mark the article as failed an L1/L2 stage
     */
    @action
    async fail(stage, params) {
        this.saving = true;
        let { data } = await http.post(
            `/project/${this.project}/article/${this.article._id}/${stage}/fail`,
            params,
        );

        this.article.set(data);

        events.emit("article.update", this.article);
        this.saving = false;
    }

    /**
     * Switch the article type
     */
    @action
    async addType(type) {
        this.saving = true;

        let { data } = await http.post(
            `/project/${this.project}/article/${this.article._id}/type`,
            { type: type },
        );

        this.article.set(data);
        events.emit("article.update", this.article);

        this.saving = false;
    }

    /**
     * Remove an article type.  Note that the default type (from search)
     * cannot be removed.
     */
    @action
    async removeType(type) {
        this.saving = true;

        const { data } = await http.post(
            `/project/${this.project}/article/${this.article._id}/remove-type`,
            {
                type,
            },
        );

        this.article.set(data);
        events.emit("article.update", this.article);
        this.saving = false;
    }

    /**
     * Start the l2 review
     */
    @action
    async startL2(params) {
        // make sure we have a loaded article
        if (!this.article?._id) {
            return;
        }

        this.saving = true;
        let { data } = await http.post(
            `/project/${this.project}/article/${this.article._id}/l2/start`,
            params,
        );

        this.article.set(data);
        events.emit("article.update", this.article);
        this.saving = false;
    }

    /**
     * Save an additional data value
     */
    @AutoLoadingState({
        skipIf: (store) => !store.article?._id,
        getKeyArgs: (store, [args]) => [
            "ArticleStore",
            "updateAdditionalData",
            args.additionalData._id,
        ],
        onBefore: (store) => {
            store.saving = true;
        },
        onAfter: (store, data) => {
            store.article.set(data);
            store.saving = false;
        },
        notifyOnError: true,
        retryOnFailedConnection: true,
        maxRetries: 3,
        retryOnFailedConnectionDelay: 1000,
    })
    @action
    async updateAdditionalData({ additionalData, type }) {
        return await http.post(
            `/project/${this.project}/article/${this.article._id}/additionalData`,
            {
                type,
                additionalData,
            },
        );
    }

    @action
    async addVariant({ title }) {
        try {
            const { data } = await http.post(
                `/project/${this.project}/article/${this.article._id}/dataVariants`,
                {
                    title,
                },
            );

            this.article.set(data);
            events.emit("article.update", this.article);
        } catch (error) {
            notify.error("Error adding variant");
        }
    }

    @action
    async renameVariant({ variantId, title }) {
        try {
            const { data } = await http.put(
                `/project/${this.project}/article/${this.article._id}/dataVariants/${variantId}`,
                {
                    title,
                },
            );

            this.article.set(data);
            events.emit("article.update", this.article);
        } catch (error) {
            notify.error("Error renaming variant");
        }
    }

    @action
    async deleteVariant({ variantId }) {
        try {
            const { data } = await http.delete(
                `/project/${this.project}/article/${this.article._id}/dataVariants/${variantId}`,
            );

            this.article.set(data);
            events.emit("article.update", this.article);
        } catch (error) {
            notify.error("Error deleting variant");
        }
    }

    /**
     * Generate suggestions for additional data
     */
    @action
    async generateSuggestionsForAdditionalData({ type }) {
        if (!this.article?._id) {
            return;
        }

        this.article.processing.additionalData = true;
        try {
            await http.post(
                `/project/${this.project}/article/${this.article._id}/additionalData/suggestions`,
                { type },
            );
        } catch (error) {
            notify.error(
                "Error generating suggestions for this article. " +
                    "This may be due to the AI service experiencing technical difficulties. " +
                    "Please try again later or contact our team for further assistance.",
            );
        }
    }

    /**
     * Save an answered question
     */
    @action
    async answer({ question, type }) {
        // make sure we have a loaded the article
        if (!this.article?._id) {
            return;
        }

        this.saving = true;
        let { data } = await http.post(
            `/project/${this.project}/article/${this.article._id}/l2/answer`,
            {
                type,
                question,
            },
        );

        this.article.set(data);
        this.saving = false;
    }

    /**
     * Edit the questions for complete review
     */
    @action
    async editReview(params) {
        // make sure we have a loaded the article
        if (!this.article?._id) {
            return;
        }

        this.saving = true;
        let { data } = await http.post(
            `/project/${this.project}/article/${this.article._id}/l2/edit`,
            params,
        );

        this.article.set(data);
        events.emit("article.update", this.article);

        this.saving = false;
    }

    /**
     * Save an answered question
     */
    @action
    async completeL2(params) {
        // make sure we have a loaded the article
        if (!this.article?._id) {
            return;
        }

        this.saving = true;
        let { data } = await http.post(
            `/project/${this.project}/article/${this.article._id}/l2/complete`,
            params,
        );

        this.article.set(data);
        events.emit("article.update", this.article);
        this.saving = false;
    }

    /**
     * Cancel an L2 review
     */
    @action
    async cancelL2(params) {
        // make sure we have a loaded the article
        if (!this.article?._id) {
            return;
        }

        this.saving = true;
        let { data } = await http.post(
            `/project/${this.project}/article/${this.article._id}/l2/cancel`,
            params,
        );

        this.article.set(data);
        this.saving = false;
    }

    // Reset review status
    @action
    async resetReviewStatus({ stage, type }) {
        this.saving = true;

        let {
            data,
        } = await http.post(
            `/project/${this.project}/article/${this.article._id}/${stage}/resetStatus`,
            { type },
        );
        this.article.set(data);
        events.emit("article.update", this.article);
        this.saving = false;
    }

    /**
     * Save a highlight
     */
    @action
    async saveHighlight(hl) {
        // make sure we have a loaded the article
        if (!this.article?._id) {
            return;
        }

        if (hl._id) {
            // update existing highlight
            const { data } = await http.post(
                `/project/${this.project}/article/${this.article._id}/highlight/${hl._id}`,
                hl,
            );

            this.highlights = this.highlights.map((hl) => {
                if (hl._id === data._id) {
                    return data;
                } else {
                    return hl;
                }
            });
        } else {
            // add a new highlight
            const { data } = await http.put(
                `/project/${this.project}/article/${this.article._id}/highlight`,
                hl,
            );

            this.highlights.unshift(new Highlight(data));
        }
    }

    /**
     * Remove a highlight
     */
    @action
    async removeHighlight(hl) {
        let found = this.highlights.find((el) => el._id === hl._id);
        if (found) {
            let idx = this.highlights.indexOf(found);
            this.highlights.splice(idx, 1);
        }

        await http.delete(
            `/project/${this.project}/article/${this.article._id}/highlight/${hl._id}`,
        );
    }

    /**
     * Load the article highlights
     */
    @action
    async loadHighlights() {
        // make sure we have a loaded the article
        if (!this.article?._id) {
            return;
        }

        let { data } = await http.get(
            `/project/${this.project}/article/${this.article._id}/highlights`,
        );

        this.highlights = data.map((entry) => {
            return new Highlight(entry);
        });
    }

    /**
     * Load the comments
     */
    @action
    async loadComments(sort = "desc") {
        // make sure we have a loaded the article
        if (!this.article?._id) {
            return;
        }

        let { data } = await http.get(
            `/project/${this.project}/article/${this.article._id}/comments?sort=${sort}`,
        );

        this.comments = data.map((entry) => {
            return new Comment(entry);
        });
    }

    /**
     * Save a comment
     */
    @action
    async saveComment(comment, sort = "desc") {
        // make sure we have a loaded the article
        if (!this.article?._id) {
            return;
        }

        if (comment._id) {
            // update existing comment
            const { data } = await http.post(
                `/project/${this.project}/article/${this.article._id}/comment/${comment._id}`,
                comment,
            );

            let found = this.comments.find((el) => el._id === comment._id);
            if (found) {
                found = data.text;
            }
        } else {
            // add a new highlight
            const { data } = await http.put(
                `/project/${this.project}/article/${this.article._id}/comment`,
                comment,
            );

            if (sort === "desc") {
                this.comments.unshift(new Comment(data));
            } else {
                this.comments.push(new Comment(data));
            }
        }
    }

    /**
     * Remove a comment
     */
    @action
    async removeComment(comment) {
        await http.delete(
            `/project/${this.project}/article/${this.article._id}/comment/${comment._id}`,
        );

        this.comments = this.comments.filter((el) => el._id !== comment._id);
    }

    /**
     * Update an article by it's id without the need of being loaded
     */
    async updateArticleById(id, params) {
        let { data } = await http.post(`/project/${this.project}/article/${id}`, params);

        // update the currently opened article if needed
        if (this.article?._id === id) {
            this.article.set(data);
        }

        events.emit("article.update", data);
    }

    async deleteArticle(id) {
        let { data } = await http.delete(`/project/${this.project}/article/${id}`);

        if (this.article?._id === id) {
            this.article.set(data);
        }

        events.emit("article.update", data);
    }

    /**
     * Resolve a comment
     */
    @action
    async resolveComment(params) {
        const { data } = await http.put(
            `/project/${this.project}/article/${this.article._id}/comment/${params._id}/resolve`,
        );

        let comment = this.comments.find((el) => el._id === params._id);

        if (comment) {
            comment.status = data.status;
            comment.resolvedBy = data.resolvedBy;
            comment.resolvedOn = data.resolvedOn;
            comment.replies = data.replies;
        }
    }

    /**
     * Mark a comment as pending
     */
    @action
    async unResolveComment(params) {
        const { data } = await http.put(
            `/project/${this.project}/article/${this.article._id}/comment/${params._id}/unresolve`,
        );

        let comment = this.comments.find((el) => el._id === params._id);

        if (comment) {
            comment.status = data.status;
            comment.resolvedBy = undefined;
            comment.resolvedOn = undefined;
            comment.replies = data.replies;
            if (!comment.expanded) {
                comment.expanded = true;
            }
        }
    }

    /**
     * Add a reply to an existing comment
     */
    @action
    async addReply(params) {
        const { data } = await http.post(
            `/project/${this.project}/article/${this.article._id}/comment/${params.comment._id}/reply`,
            {
                text: params.text,
            },
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);
        comment.replies = data.replies;
    }

    /**
     * Update an existing comment's reply
     */
    @action
    async updateReply(params) {
        const { data } = await http.put(
            `/project/${this.project}/article/${this.article._id}/comment/${params.comment._id}/reply/${params.replyId}`,
            {
                reply: params.reply,
                text: params.text,
            },
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);

        comment.replies = data.replies;
    }
    /**
     * Remove an existing reply
     */
    @action
    async removeReply(params) {
        const { data } = await http.delete(
            `/project/${this.project}/article/${this.article._id}/comment/${params.comment._id}/reply/${params.replyId}`,
        );

        const comment = this.comments.find((el) => el._id === params.comment._id);
        comment.replies = data.replies;
    }

    @action
    async getReusableArticles(id, { stage, type }) {
        let { data } = await http.post(`/project/${this.project}/articles/reusable`, {
            id,
            stage,
            type,
        });
        return data.map((v) => new Article(v));
    }

    @action
    async reuseArticle(args) {
        await http.post(`/project/${this.project}/articles/reuse`, args);
    }
}

export default new ArticleStore();
