import MarkdownIt from "markdown-it";

const md = new MarkdownIt();
md.set({ html: true });

export function toHtml(content) {
    if (content === undefined) {
        return content;
    }

    return md.render(content);
}

export default {
    toHtml,
};
