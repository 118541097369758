/**
 * Triggers a file containing the specified content to be downloaded in the user's browser.
 * @param {string} filename - The name of the file when downloaded.
 * @param {string} content - The complete contents of the file.
 */
export function triggerDownload(filename, content) {
    // Create blob link to download
    const url = window.URL.createObjectURL(new Blob([content]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

function getFileExtension(url) {
    const match = url.match(/\.([a-zA-Z0-9]+)(\?|#|$)/);
    return match ? match[0] : "";
}

function downloadWithFilename(url, filename) {
    const link = document.createElement("a");

    fetch(url).then((res) => {
        res.blob().then((blob) => {
            const url = window.URL.createObjectURL(blob);
            link.href = url;
            link.download = filename + getFileExtension(res.url);
            link.click();
            document.body.appendChild(link);
            link.parentNode.removeChild(link);
        });
    });
}

/**
 * Triggers a file download
 * @param {string} id - The id of the file to be downloaded.
 * @param {string} filename - The name of the file when downloaded.
 */

export function download(id, filename) {
    const link = document.createElement("a");

    if (filename) {
        return downloadWithFilename(`/api/file/${id}`, filename);
    }

    link.href = `/api/file/${id}`;

    link.setAttribute("target", "_blank");
    link.setAttribute("download", "");

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

export function downloadWithHighlights(id, filename) {
    if (filename) {
        return downloadWithFilename(`/api/file/${id}/highlights`, filename);
    }

    // Create a link to download
    const link = document.createElement("a");
    link.href = `/api/file/${id}/highlights`;

    link.setAttribute("target", "_blank");
    link.setAttribute("download", "");

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

export default {
    download,
    triggerDownload,
    downloadWithHighlights,
};
