import React from "react";
import { Modal as AntModal } from "antd";

import "./style.scss";

const Modal = ({
    onOk,
    onCancel,
    okText,
    cancelText,
    okType,
    okButtonProps,
    cancelButtonProps,
    maskClosable,
    keyboard,
    children,
    ...props
}) => {
    okButtonProps = okButtonProps ?? {
        type: okType ?? "primary",
        role: "btn-ok",
    };

    cancelButtonProps = cancelButtonProps ?? {
        type: "default",
    };

    okText = okText ?? "OK";
    cancelText = cancelText ?? "Cancel";
    maskClosable = maskClosable ?? false;
    keyboard = keyboard ?? false;

    /**
     * Exclude the article
     */
    const handleOk = (event) => {
        // this was invoked from the OK button
        if (event.currentTarget.getAttribute?.("role") === "btn-ok") {
            return onOk && onOk();
        }

        handleCancel();
    };

    /**
     * Close the modal
     */
    const handleCancel = (event) => {
        onCancel && onCancel();
    };

    return (
        <AntModal
            onOk={handleCancel}
            onCancel={handleOk}
            okText={cancelText}
            cancelText={okText}
            okButtonProps={cancelButtonProps}
            cancelButtonProps={okButtonProps}
            maskClosable={maskClosable}
            keyboard={keyboard}
            {...props}
        >
            {children}
        </AntModal>
    );
};

export default Modal;
