import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { observer } from "mobx-react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tabs } from "antd";

import BookmarkModal from "../bookmark/modal";
import BookmarkDetailsModal from "../bookmark/details-modal";
import Groups from "./groups";

import notify from "@app/components/notify";
import bookmarksStore from "@app/state/store/report/document/bookmarks";
import session from "@app/state/store/session";
import useComponentWillUnmount from "@app/hooks/useComponentWillUnmount";
import { Search } from "@app/components/filter";

import "./style/bookmarks.scoped.scss";

const ALL_TAB = "All";
const My_TAB = "My bookmarks";

const Bookmarks = observer(
    ({ document, onOpen, readonly = false, keepBookmarksOnUnmount = false }) => {
        const [edit, setEdit] = useState(null);
        const [details, setDetails] = useState(null);
        const [activeKey, setActiveKey] = useState(ALL_TAB);
        const search = useLocation().search;
        const selected = new URLSearchParams(search).get("bid");

        const { filteredGroupedBookmarks } = bookmarksStore;

        useComponentWillUnmount(() => {
            if (!keepBookmarksOnUnmount) {
                bookmarksStore.reset();
            }
        });

        useEffect(() => {
            if (
                (bookmarksStore.bookmarks === null || bookmarksStore.document !== document) &&
                !bookmarksStore.loading
            ) {
                bookmarksStore.load(document);
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [document]);

        // do not render the component before initialization
        if (bookmarksStore.bookmarks === null) {
            return;
        }

        /**
         * Save a bookmark
         */
        const save = async (data) => {
            try {
                await bookmarksStore.save(data);
            } catch (ex) {
                notify.error("Error saving the bookmark");
            }
        };

        /**
         * Delete a bookmark
         */
        const remove = async (bookmark) => {
            try {
                await bookmarksStore.remove(bookmark);
            } catch (ex) {
                notify.error("Error removing the bookmark");
            }
        };

        const switchTab = (key) => {
            setActiveKey(key);
            bookmarksStore.filter.filter("user", key === My_TAB ? session.user.fullName : null);
        };

        return (
            <div className="bookmarks">
                {!bookmarksStore.loading && !bookmarksStore.bookmarks?.length && (
                    <div className="empty">
                        <div className="icon">
                            <InfoCircleOutlined />
                        </div>
                        {document && (
                            <div className="text">
                                There are no bookmarks for this document.
                                <br />
                                You can create a new bookmark by opening the document, creating a
                                bookmark and assign it to a category
                            </div>
                        )}

                        {!document && (
                            <div className="text">
                                There are no bookmarks for this project.
                                <br />
                                You can create a new bookmark by opening a document, creating a
                                bookmark and assign it to a category
                            </div>
                        )}
                    </div>
                )}

                {bookmarksStore.bookmarks.length > 0 && (
                    <div className="list">
                        <Search filter={bookmarksStore.filter} searchOnChange />
                        <Tabs size="small" activeKey={activeKey} onTabClick={switchTab}>
                            <Tabs.TabPane tab={ALL_TAB} key={ALL_TAB}></Tabs.TabPane>

                            <Tabs.TabPane tab={My_TAB} key={My_TAB}></Tabs.TabPane>
                        </Tabs>

                        <Groups
                            groups={filteredGroupedBookmarks}
                            selected={selected}
                            onOpen={onOpen}
                            setEdit={setEdit}
                            remove={remove}
                            setDetails={setDetails}
                            readonly={readonly}
                        />
                    </div>
                )}

                {edit && (
                    <BookmarkModal
                        visible={!!edit}
                        bookmark={edit}
                        onSave={(data) => {
                            setEdit(null);
                            save(data);
                        }}
                        onDiscard={() => {
                            setEdit(null);
                        }}
                    />
                )}

                {details && (
                    <BookmarkDetailsModal
                        visible={!!details}
                        bookmark={details}
                        onDiscard={() => {
                            setDetails(null);
                        }}
                    />
                )}
            </div>
        );
    },
);

export default Bookmarks;
