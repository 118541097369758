import notify from "@app/components/notify";

export class DownloadService {
    static async downloadDocuments({
        projectId,
        categoryId = null,
        documentIds,
        filterParams = {},
        exportFormat = "zip",
    }) {
        try {
            const params = {
                project: projectId,
                format: exportFormat,
                ...(documentIds?.length > 0 && { documentIds: documentIds.join(",") }),
                ...filterParams,
            };

            if (categoryId) {
                params.category = categoryId;
            }

            if (filterParams.category) {
                params.category = filterParams.category;
            }

            const queryString = new URLSearchParams(params).toString();

            const link = document.createElement("a");
            link.href = `/api/project/${projectId}/documents/export?${queryString}`;

            link.setAttribute("target", "_blank");
            link.setAttribute("download", "");

            document.body.appendChild(link);

            // Start download
            link.click();

            link.parentNode.removeChild(link);
        } catch (error) {
            const errorMessage = error.response?.data?.error || "Failed to download documents";
            notify.error(errorMessage);
            console.error("Download error:", error);
        }
    }
}
