import BaseStore from "../base";
import { action } from "mobx";
import http from "@app/lib/http";
import notify from "@app/components/notify";
import ImageModel from "@app/state/model/report-document/image";
import reportDocuments from "@app/state/store/report-document/report-documents";

export class ImageStore extends BaseStore {
    observable() {
        return {
            loading: false,
        };
    }

    @action
    async format(file, { clientId, projectId }) {
        if (!file) {
            console.log("no file or projectId");

            return;
        }

        this.loading = true;
        let formData = new FormData();
        formData.append("file", file);

        if (clientId) {
            formData.append("clientId", clientId);
        }
        if (projectId) {
            formData.append("projectId", projectId);
        }

        try {
            let { data } = await http.post(`/image/upload`, formData);
            if (data) {
                return new ImageModel(data);
            }
        } catch (error) {
            notify.error("Cannot format file!");
        } finally {
            this.loading = false;
        }
    }

    @action
    async formatImage({ type, value, caption, clientId, projectId }) {
        const convertFileToImage = async (file) => {
            const image = await this.format(file, { clientId, projectId });
            if (image) {
                return {
                    file: image.file,
                    processed: true,
                    height: image.height,
                    width: image.width,
                    caption: caption,
                };
            }
        };

        if (type === "file") {
            return await convertFileToImage(value);
        } else if (type === "src") {
            const src = value;
            if (src) {
                const data = src.split(",");
                if (data[0].match(/data:image.+base64/)) {
                    var binary = window.atob(data[1]);
                    var array = [];
                    for (var i = 0; i < binary.length; i++) {
                        array.push(binary.charCodeAt(i));
                    }
                    var file = new Blob([new Uint8Array(array)], { type: "image/jpeg" });

                    return await convertFileToImage(file);
                }
            }
        }
    }

    /**
     * Get a unique list of image guids that have been used within the report document
     */
    get images() {
        return reportDocuments.figureSet;
    }
}

export default new ImageStore();
