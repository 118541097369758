import { observable } from "mobx";
import Model from "@app/state/model/base";

const RESPONSE_TIMEOUT = 300;

export default class ChatHistory extends Model {
    @observable _id = undefined;
    @observable project = undefined;
    @observable source = {
        _id: undefined,
        type: undefined,
        fileId: undefined,
    };
    @observable user = {
        _id: undefined,
        fullName: undefined,
        avatar: undefined,
    };
    @observable role = undefined;
    @observable content = undefined;
    @observable answer = undefined;
    @observable created = undefined;
    @observable status = undefined;

    get success() {
        return this.status === "success" && this.answer?.content;
    }

    get failed() {
        if (this.status === "processing" || this.status === "indexing") {
            const elapsed = (new Date() - new Date(this.created)) / 1000;
            if (elapsed > RESPONSE_TIMEOUT) {
                return true;
            }
        }

        return this.status === "error";
    }

    get processing() {
        return !this.failed && this.status === "processing";
    }

    get indexing() {
        return !this.failed && this.status === "indexing";
    }
}
