import React from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import { Button, Menu, Dropdown } from "antd";
import Avatar from "@app/components/user/avatar";
import Card from "@app/components/card/card";
import file from "@app/lib/file";
import format from "@app/lib/format";
import {
    CloudDownloadOutlined,
    EllipsisOutlined,
    ReadOutlined,
    DeleteOutlined,
} from "@ant-design/icons";
import Status from "./status";
import Tags from "@app/components/tags/tags";
import "./style/card.scoped.scss";
import { useContext } from "@app/components/multi-select/context";
import { MultiSelectCheckBox } from "@app/components/multi-select/multi-select";

const DocumentCard = observer(
    ({
        document,
        className,
        onOpen,
        onDelete,
        assignedTags,
        checkedBox,
        readonly = false,
        ...rest
    }) => {
        const { context } = useContext();
        const { isChecked, toggle } = context;

        const onDownload = () => file.download(document.file._id);
        const actions = (
            <Menu className="menu">
                {document.canOpen && (
                    <Menu.Item onClick={onOpen} icon={<ReadOutlined />}>
                        Open
                    </Menu.Item>
                )}
                <Menu.Item onClick={onDownload} icon={<CloudDownloadOutlined />}>
                    Download
                </Menu.Item>

                {!readonly && <Menu.Divider />}
                {!readonly && (
                    <Menu.Item onClick={onDelete} icon={<DeleteOutlined />}>
                        Delete
                    </Menu.Item>
                )}
            </Menu>
        );

        const Actions = observer(() => {
            return (
                <div className="menu" onClick={(e) => e.stopPropagation()}>
                    <Dropdown overlay={actions} trigger="click" placement="bottomRight">
                        <Button type="icon" icon={<EllipsisOutlined />} />
                    </Dropdown>
                </div>
            );
        });

        return (
            <>
                <Card
                    className={classNames("card", className)}
                    {...rest}
                    selected={isChecked ? isChecked(document._id) : false}
                    onClick={(e) => {
                        if (e.ctrlKey || e.metaKey) {
                            e.preventDefault();
                            toggle(document._id);
                            console.log("ctrlKey", document._id);
                        }

                        if (e.shiftKey) {
                            e.preventDefault();
                            toggle(document._id, { range: true });
                        }
                    }}
                >
                    <Actions />
                    <div className="name_container">
                        {checkedBox && (
                            <div className="checkbox">
                                <MultiSelectCheckBox id={document._id} />
                            </div>
                        )}
                        <div className="name">
                            {document.name?.length > 140
                                ? document.name.toString().substr(0, 140).concat("...")
                                : document.name}
                        </div>
                    </div>

                    <div className="meta">
                        <Avatar user={document.author} size={40} className="avatar" />
                        {document.author.fullName} uploaded version <b>{document.version}</b> on{" "}
                        {format.date(document.created)}
                    </div>
                    <div className="status">
                        <Status status={document.status} />
                    </div>
                    <Tags assignedTags={assignedTags} />
                </Card>
            </>
        );
    },
);

export default DocumentCard;
