import React, { useCallback, useEffect, useState } from "react";
import { observer } from "mobx-react";
import CaptionModal from "@app/components/caption/modal";
import imageStore from "@app/state/store/report-document/image";

const Content = observer(({ editor }) => {
    const [entry, setEntry] = useState(null);
    const [showCaption, setShowCaption] = useState(false);

    const onShowPicker = useCallback(() => {
        var input = document.createElement("input");
        input.type = "file";

        input.onchange = (e) => {
            var file = e.target.files[0];
            pick(file);
        };

        input.click();
    });

    const onShowEditCaption = useCallback(() => {
        setShowCaption(true);
    });

    const onFormatImage = async ({ type, value, caption }) => {
        const newImage = await imageStore.formatImage({
            type,
            value,
            caption,
            clientId: editor.options.editorProps.clientId,
            projectId: editor.options.editorProps.projectId,
        });

        editor.chain().focus().addImageEntry({ entry: newImage }).run();
    };

    useEffect(() => {
        if (!editor) {
            return;
        }

        editor.on("imagePicker", onShowPicker);
        editor.on("formatImage", onFormatImage);

        return () => {
            editor.off("imagePicker", onShowPicker);
            editor.off("formatImage", onFormatImage);
        };
    }, [editor]);

    const closeEditCaption = () => {
        setShowCaption(false);
    };

    const pick = async (entry) => {
        setEntry(entry);
        onShowEditCaption(true);
    };

    const save = ({ caption }) => {
        closeEditCaption();

        editor.emit("formatImage", { type: "file", value: entry, caption });
    };

    return (
        <>
            <CaptionModal
                title="Add Figure Caption"
                open={showCaption}
                onSubmit={save}
                onCancel={closeEditCaption}
            />
        </>
    );
});

export default Content;
