import React, { useState } from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import notify from "@app/components/notify";
import { observer } from "mobx-react";
import { useUploadContext } from "@app/components/form/upload/context";
import { DownloadService } from "@app/components/form/download/service";
import documents from "@app/state/store/report/document/list";
import { useContext as useMultiSelectContext } from "@app/components/multi-select/context";

const DownloadButton = observer(
    ({ categoryId = null, allCategories = false, disabled = false }) => {
        const [loading, setLoading] = useState(false);
        const { projectId } = useUploadContext(); // Access projectId from context
        const { context } = useMultiSelectContext();
        const { selected } = context;

        const checkedDocumentsIds = selected
            ?.filter((item) => item.checked)
            ?.map((item) => item._id);

        const handleDownload = async () => {
            setLoading(true);

            try {
                const filterParams = {
                    projectId,
                    exportFormat: "zip",
                    ...(categoryId && !allCategories ? { categoryId } : {}),
                    filterParams: documents.filter.value(),
                    documentIds: checkedDocumentsIds,
                };

                await DownloadService.downloadDocuments(filterParams);
                notify.success(
                    "The download may take a while if you selected too many files. Please be patient while the files are being processed.",
                );
            } catch (error) {
                notify.error("Failed to start download");
                console.error("Download error:", error);
            } finally {
                setLoading(false);
            }
        };

        return (
            <Button
                icon={<DownloadOutlined />}
                loading={loading}
                onClick={handleDownload}
                type="primary"
                disabled={disabled}
            >
                Download Documents
            </Button>
        );
    },
);

export default DownloadButton;
