import React from "react";

import { Steps, Modal, Button } from "antd";
import { observer } from "mobx-react";
import state from "@app/state/store/report/import-search";

import { UploadStep } from "./upload";
import { SearchSummary } from "./summary";
import { L1Reasons, L2Reasons } from "./reasons";
import { Review } from "./review";
import template from "@app/assets/Literature Review Import Template.xlsx";

import "./style/modal.scoped.scss";

export function Stepper({ steps, current }) {
    return (
        <Steps
            current={current}
            progressDot
            labelPlacement="vertical"
            size="small"
            style={{
                marginLeft: "-14px",
            }}
        >
            {steps.map((item) => (
                <Steps.Step
                    key={item.title}
                    title={
                        <div
                            style={{
                                width: "140px",
                            }}
                        >
                            {item.title}
                        </div>
                    }
                />
            ))}
        </Steps>
    );
}

export const ImportSearchModal = observer(({ visible, onImport, onCancel }) => {
    const next = () => {
        if (state.currentStep === state.steps.length - 1) {
            return;
        }
        state.currentStep += 1;
        state.steps[state.currentStep].status = "process";
        state.steps[state.currentStep - 1].status = "finish";
    };

    const handleUploadStep = async () => {
        return await state.loadSearchesFromFile();
    };

    const handleSearchSummary = async () => {
        for (let search of state.searches) {
            await search.validateFields();
        }

        if (state.searches.some((search) => !search.isValid)) {
            return false;
        }

        state.updateL1Reasons();
        state.updateL2Reasons();

        return true;
    };

    const handleL1Reasons = () => {
        return true;
    };

    const handleL2Reasons = () => {
        return true;
    };

    const handleReview = async () => {
        const result = await state.saveSearchResults();
        if (result) {
            state.reset();
            onImport();
        }
    };

    const stepActions = [
        {
            disabled: !state.file,
            action: handleUploadStep,
        },
        {
            disabled: false,
            action: handleSearchSummary,
        },
        {
            disabled: false,
            action: handleL1Reasons,
        },
        {
            disabled: false,
            action: handleL2Reasons,
        },
        {
            disabled: false,
            action: handleReview,
        },
    ];

    const nextStep = async () => {
        const isValidStep = await stepActions[state.currentStep].action();
        if (isValidStep) {
            next();
        }
    };

    const stepContents = [
        <UploadStep />,
        <SearchSummary />,
        <L1Reasons />,
        <L2Reasons />,
        <Review />,
    ];

    return (
        <Modal
            title="Import Appraisal Data"
            okText="Import"
            visible={visible}
            maskClosable={false}
            keyboard={false}
            width={1000}
            onCancel={() => {
                state.reset();
                onCancel();
            }}
            className="import-search-modal"
            footer={
                <div className="step-actions">
                    {state.currentStep === 0 && (
                        <div className="step-actions-content">
                            <span>
                                You can download a template file to help you fill in the data
                            </span>
                            <a href={template} download="Literature Review Import Template.xlsx">
                                <Button>Download</Button>
                            </a>
                        </div>
                    )}
                    {state.currentStep > 1 && (
                        <Button onClick={() => (state.currentStep -= 1)}>Previous</Button>
                    )}
                    {state.currentStep < state.steps.length - 1 && (
                        <Button
                            onClick={nextStep}
                            loading={state.loading}
                            disabled={stepActions[state.currentStep].disabled}
                        >
                            Next
                        </Button>
                    )}
                    {state.currentStep === state.steps.length - 1 && (
                        <Button type="primary" onClick={nextStep} loading={state.loading}>
                            Save
                        </Button>
                    )}
                </div>
            }
        >
            <div className="modal-content">
                <Stepper steps={state.steps} current={state.currentStep} />

                <div className="step-content">{stepContents[state.currentStep]}</div>
            </div>
        </Modal>
    );
});
