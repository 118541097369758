import React from "react";
import cn from "classnames";
import { List, Checkbox, Tag } from "antd";

import "./style/list-item.scoped.scss";

export const CitationListItem = ({ citation, onClick, className, isSelected }) => {
    const previousLabel = citation.type?.includes("previous") ? "<b>Prev. Ref. </b>" : "";

    const onChange = () => {
        onClick();
    };

    const formatCitation = () => {
        let citationString = "";
        if (citation.authors?.length > 0) {
            citationString += `${citation.authors[0].firstName} ${citation.authors[0].lastName} ${
                citation.authors.length > 1 ? "et al." : ""
            }`;
        }

        if (citation.publicationYear) {
            citationString += `, ${citation.publicationYear}`;
        }

        if (citation.doi) {
            citationString += ` (DOI: ${citation.doi})`;
        }

        return citationString ? ` - ${citationString}` : "";
    };

    return (
        <List.Item
            key={citation._id}
            className={cn("citation-list-item", {
                // [className]: true,
            })}
            children={
                <List.Item.Meta
                    key={citation._id}
                    avatar={<Checkbox checked={isSelected} onChange={onChange} />}
                    description={
                        <>
                            <div className="citation-list-item-description" onClick={onClick}>
                                {previousLabel} {citation.highlightedTitle} {formatCitation()}
                            </div>
                            <div className="tags">
                                {citation.type === "document" && (
                                    <Tag color={"purple"}>Source Document</Tag>
                                )}
                                {citation.type === "due" && <Tag color={"blue"}>Search Result</Tag>}
                                {citation.type === "sota" && (
                                    <Tag color={"green"}>Search Result</Tag>
                                )}
                            </div>
                        </>
                    }
                />
            }
        />
    );
};
