import { observer } from "mobx-react";
import { Node } from "prosemirror-model";

import versionStore from "@app/state/store/report-document/version";
import documentStore from "@app/state/store/report-document/document";

import {
    emptyContent,
    removeCommentReferences,
    getHtmlString,
    addDiffModeToTags,
} from "../diff-utility";

import { useLoadVersionedContent } from "../hooks";

export const VersionAliasView = observer(({ editor, sectionId, versionId }) => {
    const { hasLoaded, content } = useLoadVersionedContent({
        versionId,
        sectionId,
    });

    if (!hasLoaded) {
        return null;
    }

    const rhs = removeCommentReferences(content.rhs);

    const right =
        rhs[sectionId] && Array.isArray(rhs[sectionId]?.content) ? rhs[sectionId] : emptyContent;

    const doc = Node.fromJSON(editor.schema, right);
    const html = addDiffModeToTags(getHtmlString(editor.schema, doc.content));

    editor.commands.setContent(html);
    versionStore.updateChangeCounter();
    documentStore.processSection(sectionId, editor.getJSON());
});

export default VersionAliasView;
