import React, { useEffect, useMemo, useRef } from "react";
import { observer } from "mobx-react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Spin } from "antd";
import { CopyOutlined, LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import Avatar from "@app/components/user/avatar";
import format from "@app/lib/format";
import md from "@app/lib/md";
import logo from "@app/assets/logo-icon2.svg";

import "./style/ai-chat.scoped.scss";

const SpinIcon = <LoadingOutlined style={{ fontSize: 18, marginRight: 10 }} spin />;

const AIChatHistory = observer(({ history: messageHistory = [] }) => {
    const bottomRef = useRef(null);
    const history = useHistory();
    const location = useLocation();
    const last = messageHistory[messageHistory.length - 1];

    useEffect(() => {
        if (bottomRef && bottomRef.current) {
            bottomRef.current.scrollIntoView({
                behavior: "smooth",
            });
        }
    }, [messageHistory.length, last?.status]);

    /*
     * Open the file and highlight the source
     */
    function highlightSource(source) {
        const points = source.coordinates.points;
        const width = source.coordinates.layout_width;
        const height = source.coordinates.layout_height;

        const x1 = (points[0][0] / width) * 100; // x from top left corner
        const x2 = (points[2][0] / width) * 100; // x from bottom right corner
        const y1 = (points[0][1] / height) * 100; // y from top left corner
        const y2 = (points[1][1] / height) * 100; // y from bottom left corner

        let area = {
            pageIndex: source.page - 1,
            left: x1,
            top: y1,
            height: y2 - y1,
            width: x2 - x1,
        };

        let link = location.pathname + `?view-file=true&focus=true&area=${JSON.stringify(area)}`;
        history.push(link);
    }

    return (
        <div className="historyContainer">
            {messageHistory.map((item) => {
                return (
                    <div key={item._id} className="historyGroup">
                        <ChatItem item={item} highlightSource={highlightSource} />
                    </div>
                );
            })}
            <div ref={bottomRef}></div>
        </div>
    );
});

const ChatItem = observer(({ item, highlightSource }) => {
    const answer = useMemo(() => {
        const content = item.answer?.content;
        if (content) {
            return md.toHtml(content);
        }

        return undefined;
    }, [item.answer?.content]);

    const copy = () => {
        const data = {};
        data["text/plain"] = new Blob([item.answer.content], { type: "text/plain" });
        data["text/html"] = new Blob([answer], { type: "text/html" });
        navigator.clipboard.write([new window.ClipboardItem(data)]);
    };

    return (
        <div className="chatItem">
            <div className="chatSignature">
                <Avatar className={`avatar`} user={item.user} size={40} />
                <div>
                    <div>{item.user?.fullName}</div>
                    <div className="dateTime">{format.datetime(item.created)}</div>
                </div>
            </div>
            <div className="chatContent">{item.content}</div>

            <div className="answer">
                <div className="chatSignature">
                    <img src={logo} className="logo" />
                    <div>
                        <div>CAPTIS Copilot</div>
                        <div className="dateTime">{format.datetime(item.answer.created)}</div>
                    </div>

                    {(item.processing || item.indexing) && (
                        <div className="loading">
                            <Spin indicator={SpinIcon} />
                        </div>
                    )}

                    {item.success && (
                        <div>
                            <Button
                                type="icon"
                                icon={<CopyOutlined />}
                                className="copy"
                                onClick={copy}
                            />
                        </div>
                    )}
                </div>
                {item.indexing && (
                    <div className="chatContent">
                        Indexing source file.
                        <br />
                        Sit tight, the process may take a few minutes
                    </div>
                )}
                {item.processing && <div className="chatContent">Processing your request</div>}
                {item.failed && <div className="chatContent">Error processing your request</div>}
                {item.success && (
                    <>
                        <div
                            className="chatContent html"
                            dangerouslySetInnerHTML={{ __html: answer }}
                        ></div>
                        {item.answer.source?.page && (
                            <div
                                className="link"
                                onClick={() => highlightSource(item.answer.source)}
                            >
                                <LinkOutlined /> Source
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    );
});

export default AIChatHistory;
