import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { isEqual } from "lodash";
import { useParams } from "react-router-dom";
import cn from "classnames";

import { ReportDocumentSection } from "@app/constants";

import Content from "./content";
import { useEditor } from "./common";
import EventPublisher from "./event-publisher";

import { ImagePlugin } from "./modules/image";
import { TablePlugin } from "./modules/table";
import { DictionaryPicker } from "./modules/dictionary";
import { AbbreviationPlugin } from "./modules/abbreviation";
import { LoadingMask } from "./modules/loading";
import { ClipboardWarning } from "./modules/clipboard";
import { CitationPlugin } from "./modules/citation";
import { CaptisLinkPlugin } from "./modules/captis-link";
import { LinkPlugin } from "./modules/link";
import EditorModal from "./modules/editor-modal";
import { CommentPlugin } from "./modules/comment";
import { CustomObjectPlugin } from "./modules/custom-object";
import HelpTextDeleteWarning from "./modules/help-text/help-text-delete-notification";

import "./styles/editor.scoped.scss";

const Editor = observer(
    ({
        value,
        onChange,
        onEditorReady,
        dictionary,
        abbreviation,
        metadata = {},
        readonly = false,
        project,
        templateMode = false,
        historyMode = false,
    }) => {
        const { project: projectId, documentId } = useParams();
        const { sectionId, clientId } = metadata;
        const [previousWord, setPreviousWord] = useState();
        const [isReady, setReady] = useState(false);

        const extractPreviousWord = (selection) => {
            const { $from } = selection;

            if ($from.nodeBefore?.isText) {
                const word = {};
                let beforeText = $from.nodeBefore.text;
                beforeText = beforeText.split(" ");
                beforeText = beforeText[beforeText.length - 1];

                word.text = beforeText;
                word.length = beforeText.length;
                word.from = selection.from - beforeText.length;
                word.to = word.from + word.length;

                return word;
            }
        };

        /**
         * Define the debounced function to handle update events
         */
        const onUpdate = (editor) => {
            if (onChange) {
                onChange(editor);
            }
        };

        /**
         * Configure the editor
         */
        const editor = useEditor({
            content: value,
            onUpdate: ({ editor }) => {
                onUpdate(editor);
            },
            editorProps: {
                clientId,
                projectId,
                sectionId,
                documentId,
                templateMode,
                handleKeyDown: (view, event) => {
                    if (event.code === "Space") {
                        setPreviousWord(extractPreviousWord(view.state.selection));
                    } else if (event.code === "Enter") {
                        setPreviousWord(extractPreviousWord(view.state.selection));
                    }

                    return false;
                },
            },
            metadata,
            readonly,
        });

        const focusEditor = () => {
            if (editor && !readonly) {
                // trying to address a race condition in which the editor is
                // destroyed before we focus it
                setTimeout(() => {
                    !editor.isDestroyed && editor.commands.focus("start", { scrollIntoView: true });
                }, 10);

                const { selection } = editor.state;
                if (selection?.node?.type?.name === "image") {
                    editor.commands.createParagraphNear();
                }
            }
        };

        // Listen to content changes and update the editor state
        useEffect(() => {
            if (isReady && editor && value?.type && !isEqual(editor.getJSON(), value)) {
                editor.commands.setContent(value);
            }

            focusEditor();
        }, [value, editor]);

        useEffect(() => {
            if (editor) {
                editor.sectionId = metadata.sectionId;
                setReady(true);
                onEditorReady(editor);
            }
        }, [editor, onEditorReady]);

        if (!editor) {
            return null;
        }

        const hideHelpText = () => {
            if (templateMode) {
                return false;
            }

            if (readonly) {
                return true;
            }

            return !editor.storage[ReportDocumentSection.HELP_TEXT.MARK_NAME].store.isVisible;
        };

        return (
            <div
                className={cn({
                    editor: true,
                    hideHelpText: hideHelpText(),
                })}
            >
                <Content
                    editor={editor}
                    sectionId={metadata.sectionId}
                    project={project}
                    templateMode={templateMode}
                    historyMode={historyMode}
                />
                <DictionaryPicker
                    editor={editor}
                    dictionary={dictionary}
                    templateMode={templateMode}
                    documentId={documentId}
                />
                <TablePlugin
                    editor={editor}
                    key={editor.sectionId ?? `table-${editor.sectionId}`}
                />
                <LoadingMask editor={editor} />
                <ImagePlugin editor={editor} />
                <AbbreviationPlugin
                    editor={editor}
                    abbreviation={abbreviation}
                    previousWord={previousWord}
                    templateMode={templateMode}
                    documentId={documentId}
                />
                <ClipboardWarning editor={editor} />
                {!templateMode && <CitationPlugin editor={editor} />}
                {!templateMode && <CaptisLinkPlugin editor={editor} />}
                <LinkPlugin editor={editor} />
                <EditorModal editor={editor} />
                <EventPublisher />
                {!historyMode && (
                    <CommentPlugin editor={editor} sectionId={sectionId} readonly={readonly} />
                )}
                <CustomObjectPlugin editor={editor} sectionId={sectionId} readonly={readonly} />
                <HelpTextDeleteWarning editor={editor} />
            </div>
        );
    },
);

Editor.displayName = "Editor";

export default Editor;
