import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import qs from "qs";
import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router-dom";

import { Editor } from "@app/components/report-document";
import contentHelper from "@app/components/report-document/editor/modules/utils/content";
import { getPageWidth } from "@app/components/report-document/editor/utils";

import documentStore from "@app/state/store/report-document/document";
import sectionsStore from "@app/state/store/report-document/report-document-sections";
import commentStore from "@app/state/store/report-document/comment";
import versionStore from "@app/state/store/report-document/version";
import dictionaryStore from "@app/state/store/report/dictionary/pick";
import abbreviationStore from "@app/state/store/report/abbreviation/pick";

import "./styles/section.scoped.scss";

const Section = observer(({ section, size = "A4", enableDiffPlugin }) => {
    const location = useLocation();
    const { project } = useParams();
    const ref = useRef(null);
    const [details, setDetails] = useState();

    const versionId = versionStore.versionId;
    const title = (section.displayPos ? `${section.displayPos} ` : "") + section.title;
    const width = getPageWidth(size);
    const hasContent = Boolean(Object.keys(details?.content ?? {}).length);

    const load = async () => {
        const object = await sectionsStore.loadDetails(section._id);

        documentStore.processSection(section._id, object.content);

        const { newContent } = contentHelper.sanitizeContent(object.content, {
            comments: commentStore.comments,
            abbreviations: abbreviationStore.list,
        });

        setDetails({ ...object, content: newContent });
    };

    useEffect(() => {
        if (section._id) {
            load();
        }
    }, [section._id]);

    useEffect(() => {
        if (ref && ref.current) {
            const params = qs.parse(location.search, { ignoreQueryPrefix: true });
            if (params.section === section._id) {
                ref.current.scrollIntoView({
                    behavior: "smooth",
                });
            }
        }
    }, [location]);

    if (!details) {
        return null;
    }

    return (
        <div className="section" style={{ width: width }} ref={ref}>
            <div className="header">
                <div className="title">
                    <div>{title}</div>
                </div>

                <div className="right"></div>
            </div>
            {versionId && (
                <div className={classNames("section-content")}>
                    <Editor
                        readonly={true}
                        value=""
                        onChange={() => {}}
                        onEditorReady={() => {}}
                        metadata={{
                            sectionId: section._id,
                            pos: section.pos,
                            clientId: section.client,
                        }}
                        dictionary={dictionaryStore}
                        abbreviation={abbreviationStore}
                        historyMode={true}
                    />
                </div>
            )}

            {!versionId && (
                <div className={classNames("section-content")}>
                    {details?.content && !hasContent && (
                        <div className="empty">The section is empty.</div>
                    )}

                    {hasContent && (
                        <Editor
                            readonly={true}
                            value={details?.content}
                            onChange={() => {}}
                            onEditorReady={() => {}}
                            metadata={{
                                sectionId: section._id,
                                pos: section.pos,
                                clientId: section.client,
                            }}
                            dictionary={dictionaryStore}
                            abbreviation={abbreviationStore}
                            project={project}
                            historyMode={true}
                        />
                    )}
                </div>
            )}
        </div>
    );
});

Section.displayName = "Section";

export default Section;
