import React, { useEffect, useMemo } from "react";
import { observer, useLocalStore } from "mobx-react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { Button, Select, Tooltip, Spin } from "antd";
import {
    CheckOutlined,
    CloseOutlined,
    QuestionCircleOutlined,
    LoadingOutlined,
    LinkOutlined,
} from "@ant-design/icons";
import Wand from "@app/assets/icons/wand";
import classes from "classnames";
import TextInput from "./text-input";
import md from "@app/lib/md";
import format from "@app/lib/format";

import aiStore from "@app/state/store/ai";

import { useAutoLoadingState } from "@app/hooks/useAutoLoadingState";

import "./style/additionalData.scoped.scss";

const SAVE_TIMEOUT = 5 * 1000;

const AdditionalDataField = observer(
    ({ additionalData, options, onChange, readonly = false, variants }) => {
        const history = useHistory();

        const state = useLocalStore(() => ({
            defaultValue: additionalData?.value || "",
            changed: false,
            showAIField: false,
            suggestion: undefined,
            source: undefined,
        }));
        const { isLoading, isError } = useAutoLoadingState([
            "ArticleStore",
            "updateAdditionalData",
            additionalData._id,
        ]);

        const type = additionalData?.type;
        let timer = null;

        useEffect(() => {
            const show =
                aiStore.isValidSuggestion(additionalData.ai?.suggestion) &&
                !additionalData.ai.handled;
            if (show !== state.showAIField) {
                state.showAIField = show;
            }

            if (state.suggestion !== additionalData.ai.suggestion) {
                let suggestion = format.sanitize(additionalData.ai.suggestion);
                state.suggestion = additionalData.ai.suggestion = md.toHtml(suggestion);
                state.source = additionalData.ai.source;
            }
        }, [additionalData.ai.question, additionalData.ai.suggestion, additionalData.ai.handled]);
        /**
         * Handle changes to text input. Schedules a text save event every few seconds.
         */
        const textChanged = (value) => {
            state.changed = true;

            state.defaultValue = value;
            additionalData.value = value;
            scheduleSaveText();
        };

        /**
         * Schedule a text save action after a set amount of time.
         */
        const scheduleSaveText = () => {
            clearTimeout(timer);
            timer = setTimeout(saveText, SAVE_TIMEOUT);
        };

        /**
         * Immediately emit a save event
         */
        const saveText = () => {
            if (state.changed) {
                state.changed = false;
                clearTimeout(timer);

                onChange && onChange(additionalData);
            }
        };

        const setSelectedValue = (value) => {
            // update the default value
            state.defaultValue = value;
            additionalData.value = value;

            onChange && onChange(additionalData);
        };

        const handleSuggestion = _.debounce((value) => {
            additionalData.ai.handled = true;
            setSelectedValue(value);
            state.showAIField = false;
        }, 300);

        const handleOnBlurSuggestion = () => {
            if (!additionalData.ai.handled) {
                if (additionalData.ai.suggestion !== state.suggestion) {
                    handleSuggestion(state.suggestion);
                }
            }
        };

        const onManualSave = () => {
            onChange && onChange(additionalData);
        };

        /*
         * Open the file and highlight the source
         */
        function highlightSource(source) {
            const points = source.coordinates.points;
            const width = source.coordinates.layout_width;
            const height = source.coordinates.layout_height;

            const x1 = (points[0][0] / width) * 100; // x from top left corner
            const x2 = (points[2][0] / width) * 100; // x from bottom right corner
            const y1 = (points[0][1] / height) * 100; // y from top left corner
            const y2 = (points[1][1] / height) * 100; // y from bottom left corner

            let area = {
                pageIndex: source.page - 1,
                left: x1,
                top: y1,
                height: y2 - y1,
                width: x2 - x1,
            };

            let link =
                location.pathname + `?view-file=true&focus=true&area=${JSON.stringify(area)}`;
            history.push(link);
        }

        const AITextField = useMemo(() => {
            return (
                <div className="aiFieldField">
                    <TextInput
                        value={state.suggestion}
                        readOnly={readonly}
                        onChange={(value) => {
                            state.suggestion = value;
                        }}
                        onBlur={() => {
                            handleOnBlurSuggestion();
                        }}
                    />
                    <div className="aiFieldFooter">
                        <span className="aiQuestionIcon">
                            <Wand />
                            Copilot suggestion
                            {state.source && (
                                <div className="link" onClick={() => highlightSource(state.source)}>
                                    <LinkOutlined /> Source
                                </div>
                            )}
                        </span>

                        {!readonly && (
                            <div className="aiFieldActions">
                                <Button
                                    type="primary"
                                    danger
                                    icon={<CloseOutlined />}
                                    onClick={() => handleSuggestion("")}
                                />
                                <Button
                                    type="primary"
                                    className="checkedIcon"
                                    icon={<CheckOutlined />}
                                    onClick={() => handleSuggestion(state.suggestion)}
                                />
                            </div>
                        )}
                    </div>
                </div>
            );
        }, [state.suggestion]);

        const SelectField = observer(({ defaultValue }) => {
            return (
                <Select
                    defaultValue={defaultValue}
                    onChange={setSelectedValue}
                    placeholder="Please select"
                    disabled={readonly}
                >
                    {options.map((option) => (
                        <Select.Option
                            key={option.value}
                            value={option.value}
                            selected={option.value === defaultValue}
                        >
                            {option.value}
                        </Select.Option>
                    ))}
                </Select>
            );
        });

        return (
            <div
                className={classes("fieldContainer", {
                    dangerFieldContainer: state.showAIField,
                    failedToSave: isError,
                })}
            >
                {isLoading && (
                    <div className="loadingSpinner">
                        <Spin indicator={<LoadingOutlined spin />} size="small" />
                    </div>
                )}
                <div className="fieldLabel">
                    <span className="text">
                        {additionalData?.title}
                        {variants?.length > 0 &&
                            `[${
                                variants?.find(
                                    (variant) => variant._id === additionalData.variantId,
                                )?.title
                            }]`}
                    </span>
                    {additionalData.helpMessage && (
                        <Tooltip title={additionalData.helpMessage} placement="topRight">
                            <QuestionCircleOutlined />
                        </Tooltip>
                    )}

                    {additionalData.ai?.question && (
                        <Tooltip title={additionalData.ai?.question} placement="topRight">
                            <span className="aiQuestionIcon">
                                <Wand />
                            </span>
                        </Tooltip>
                    )}
                </div>

                <div className="fieldContent">
                    {type === "text" &&
                        (state.showAIField ? (
                            AITextField
                        ) : (
                            <TextInput
                                value={state.defaultValue}
                                onChange={textChanged}
                                readOnly={readonly}
                                onBlur={(value) => {
                                    additionalData.value = value;
                                    saveText();
                                }}
                            />
                        ))}
                    {type === "select" && <SelectField defaultValue={state.defaultValue} />}
                </div>
                {isError && (
                    <div className={classes("manualSave", { underSelect: type !== "text" })}>
                        <Button size="small" type={"primary"} onClick={onManualSave}>
                            Save
                        </Button>
                    </div>
                )}
            </div>
        );
    },
);

export default AdditionalDataField;
